import { _ } from "lodash";

const namespaced = true

const state = {
    changeRequest: [],
    general: [] //Reporte Detallado
}

const mutations = {
    MUTATION_SET_CHANGE_REQUEST(state, value){
        state.changeRequest = value;

    },

    MUTATION_RESET_CHANGE_REQUEST(state){
        state.changeRequest = [];
        state.general = [];
    }
}

const actions = {
    getChangeRequest(context){
        return new Promise((resolve, reject) => {
            api.get().then(res => {
                context.commit('MUTATION_RESET_CHANGE_REQUEST');
                context.commit('MUTATION_SET_CHANGE_REQUEST');
                context.dispatch('notifications/add', {'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message}, {root: true});
            }).catch(error => {
                context.dispatch('notifications/add', {'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.data.message}, {root: true});
                reject(error.data);
            })
        });
    }
}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}